import React from "react";
import Navigation from "./config/Navigation";
import Img3 from './assets/img3.jpg'
import Img8 from './assets/img8.jpg'
import Img10 from './assets/img10.jpg'
import Img11 from './assets/img11.jpg'
import Img12 from './assets/img12.jpg'
import Img13 from './assets/img13.jpg'
import Img14 from './assets/img14.jpg'
import Img16 from './assets/img16.jpg'
import Img17 from './assets/img17.jpg'
import Img18 from './assets/img18.jpg'
import Img20 from './assets/img20.jpg'
import Img21 from './assets/img21.jpg'
import Img23 from './assets/img23.jpg'

export default function Gallery()
{
    return (
        <Navigation
            activeLink='gallery'
        >
            <section id="our-service">
                <br />
                <br />
                <div className="service">
                    <div className="service-head">
                        <h4>Our Gallery</h4>
                        <p>Magnam doloribus molestias quaerat tempore. Sint cumque quos, officia iusto magni quasi eaque deserunt reprehenderit explicabo ea, voluptates magnam aperiam nam pariatur..</p>
                    </div>
                    <div className="welcome-grid-4">
                        <div className="gallery-col">
                            <img src={Img10} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img11} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img12} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img13} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img14} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img16} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img17} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img18} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img20} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img21} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img23} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img3} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                        <div className="gallery-col">
                            <img src={Img8} alt="" />
                            <div className="gal-content">
                                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Id odio soluta suscipit beatae impedit, perferendis ea sint est libero corrupti minima animi amet totam! Numquam dolorum eligendi illo quaerat blanditiis!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Navigation>
    )
}