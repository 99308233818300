import React, { PropsWithChildren, useState } from 'react'
import { Link } from 'react-router-dom'
import Main from '../assets/main.png'

export default function Navigation({ children, activeLink } : PropsWithChildren<{ activeLink:String }>){
    const [showMenu, setShowMenu] = useState(false)
    var isShow = showMenu === false ? true : false
    return (
        <>
            <header>
                <div id="header">
                    <div className="header">
                        <div className="col header-left">
                            <img src={Main} alt=""
                                className="header-logo" />
                            <h4>Sadauki Construction Environment</h4>
                        </div>
                        {
                            !showMenu &&
                            <div className="col header-right">
                                <div className="header-mobile">
                                    <div className="header-links">
                                        <ul>
                                            <li>
                                                <Link to="/" className={'' + (activeLink === 'home' && 'active-link-large')}>Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/gallery" className={'' + (activeLink === 'gallery' && 'active-link-large')}>Gallery</Link>
                                            </li>
                                            <li>
                                                <Link to="/" className={'' + (activeLink === 'planning' && 'active-link-large')}>Planning</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="header-contact">
                                        <ul>
                                            <li>
                                                <Link to="/">
                                                    <i className="fa fa-brands fa-facebook"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <i className="fa fa-brands fa-twitter"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <i className="fa fa-brands fa-youtube"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            showMenu &&
                            <div className="col header-right">
                                <div className="header-mobile" style={{height:'auto',padding:'12px 0'}}>
                                    <div className="header-links">
                                        <ul>
                                            <li>
                                                <Link to="/" className={'' + (activeLink === 'home' && 'active-link-mobile')}>Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/gallery" className={'' + (activeLink === 'gallery' && 'active-link-mobile')}>Gallery</Link>
                                            </li>
                                            <li>
                                                <Link to="/" className={'' + (activeLink === 'planning' && 'active-link-mobile')}>Planning</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="header-contact">
                                        <ul>
                                            <li>
                                                <Link to="/">
                                                    <i className="fa fa-brands fa-facebook"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <i className="fa fa-brands fa-twitter"></i>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/">
                                                    <i className="fa fa-brands fa-youtube"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="menu-mobile">
                            <i className={"fa fa-align-right " + (showMenu && 'afterMenu') } id="menus" onClick={() => setShowMenu(isShow)}></i>
                        </div>
                    </div>
                </div>
            </header>

            <main>
                { children }
            </main>

            <section id="footer">
                <div className="footer">
                    <img src={Main} alt="" />
                    {/* <div className="footer-header">
                        <p>Contact</p>
                        <h4>Visit Office Today</h4>
                    </div> */}

                    <div className="footer-row">
                        <div className="footer-col">
                            <h3>Opening Hours</h3>
                            <div className="footer-clock">
                                <i className="fa fa-regular fa-clock"></i>
                                <p>Monday To Friday - 9am to 9pm</p>
                            </div>
                            <div className="footer-clock">
                                <i className="fa fa-regular fa-clock"></i>
                                <p>Saturday And Sunday - 8am to 11pm</p>
                            </div>
                        </div>
                        <div className="footer-col text-right justify-space-right sm-mt-6">
                            <h3>Contact Address</h3>
                            <div className="footer-clock justify-space-right">
                                <p># xxxxxxxx xxxxxxx xxxxxx</p>
                                <i className="fa fa-location-dot"></i>
                            </div>
                            <div className="footer-clock justify-space-right">
                                <p>xxxxxxxxx@gmail.com</p>
                                <i className="fa fa-regular fa-paper-plane"></i>
                            </div>
                            <div className="footer-clock justify-space-right">
                                <p>+234 - 000000000</p>
                                <i className="fa fa-phone"></i>
                            </div>
                        </div>
                    </div>

                    <div className="footer-icons">
                        <div className="footer-links">
                            <Link to="/">
                                <i className="fa fa-brands fa-facebook"></i>
                            </Link>
                            <Link to="/">
                                <i className="fa fa-brands fa-twitter"></i>
                            </Link>
                            <Link to="/">
                                <i className="fa fa-brands fa-whatsapp"></i>
                            </Link>
                            <Link to="/">
                                <i className="fa fa-brands fa-youtube"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <p style={{textAlign:'center',fontSize:'small',fontWeight:'bold'}}>Powered by Hidden Talents. &copy; 2024 All rights reserved.</p>
        </>
    )
}