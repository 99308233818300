import React from "react";
import Img1 from './assets/img1.jpg'
import Img2 from './assets/img23.jpg'
import Img4 from './assets/img21.jpg'
import Img6 from './assets/img6.jpg'
import Img7 from './assets/img7.jpg'
import Img9 from './assets/img9.jpg'
import Img5 from './assets/img5.jpg'
import Img17 from './assets/img17.jpg'
import { Link } from "react-router-dom";
import Navigation from "./config/Navigation";

export default function Index()
{
    return (
        <Navigation
            activeLink='home'
        >
            <section id="home-welcome">
                <div className="home">
                    <div className="home-content">
                        <h4>Welcome To Sadauki Construction Environment</h4>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>

                        <div className="start">
                            <div className="start-links">
                                <Link to="/">Contact Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="our-service">
                <br />
                <br />
                <div className="service">
                    <div className="service-head">
                        <h4>Our Services</h4>
                        <p>eligendi ducimus. Sequi assumenda deserunt, pariatur placeat, possimus repellat molestias voluptatem libero eaque tenetur officiis inventore modi. Labore ipsum necessitatibus velit..</p>
                    </div>
                    <div className="welcome-grid">
                        <Link to="/">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-icon">
                                        <i className="fa fa-laptop"></i>
                                    </div>
                                    <div className="card-text">
                                        <div className="justify-space">
                                            <div className="content-title">
                                                <h4>Lorem ipsum dolor sit amet</h4>
                                            </div>
                                        </div>
                                        <div className="photo">
                                            <img src={Img1} className="content-img" alt=""/>
                                            <img src={Img7} className="content-img" alt=""/>
                                        </div>
                                        <div className="text-center">
                                            <p>xxxxxxxxxxx xxxxxx xxxxxxxxx</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-icon">
                                        <i className="fa fa-house"></i>
                                    </div>
                                    <div className="card-text">
                                        <div className="justify-space">
                                            <div className="content-title">
                                                <h4>Lorem ipsum dolor sit amet</h4>
                                            </div>
                                        </div>
                                        <div className="photo">
                                            <img src={Img4} className="content-img" alt="" />
                                            <img src={Img6} className="content-img" alt="" />
                                        </div>
                                        <div className="text-center">
                                            <p>xxxxxxxxx xxxxxxxxx xxxxxxx</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        
                        <Link to="">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-icon">
                                        <i className="fa fa-car"></i>
                                    </div>
                                    <div className="card-text">
                                        <div className="justify-space">
                                            <div className="content-title">
                                                <h4>Lorem ipsum dolor sit amet</h4>
                                            </div>
                                        </div>
                                        <div className="photo">
                                            <img src={Img2} className="content-img" alt="" />
                                            <img src={Img9} className="content-img" alt="" />
                                        </div>
                                        <div className="text-center">
                                            <p>xxxxxxxxxx xxxxxxx xxxxxxx</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-icon">
                                        <i className="fa fa-land-mine-on"></i>
                                    </div>
                                    <div className="card-text">
                                        <div className="justify-space">
                                            <div className="content-title">
                                                <h4>Lorem ipsum dolor sit amet</h4>
                                            </div>
                                        </div>
                                        <div className="photo">
                                            <img src={Img5} className="content-img" alt="" />
                                            <img src={Img17} className="content-img" alt="" />
                                        </div>
                                        <div className="text-center">
                                            <p>xxxxxxxxxx xxxxxxxx xxxxxxx</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </section>


            <section id="our-service">
                <br />
                <br />
                <div className="service">
                    <div className="service-head">
                        <h4>About Us</h4>
                        <p>consectetur adipisicing elit. Laboriosam voluptas porro magni blanditiis nulla? Dolor architecto animi tempore odio fugit voluptas impedit incidunt consectetur velit, tempora unde, autem labore aliquam?</p>
                    </div>
                    <div className="welcome-grid">
                        <Link to="">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-icon">
                                        <i className="fa fa-book-open"></i>
                                    </div>
                                    <div className="card-text">
                                        <div className="justify-space">
                                            <div className="content-title">
                                                <h4>Documentation</h4>
                                                <p>Noaphp has wonderful documentation covering every aspect of the
                                                    framework. Whether you are a newcomer or have prior experience with
                                                    Noaphp, we recommend reading our documentation from beginning to end.
                                                </p>
                                            </div>
                                            <div className="content">
                                                <i className="fa fa-arrow-right text-defult"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-icon">
                                        <i className="fa fa-video"></i>
                                    </div>
                                    <div className="card-text">
                                        <div className="justify-space">
                                            <div className="content-title">
                                                <h4>Noacasts</h4>
                                                <p>Noacasts offers thousands of video tutorials on Noaphp, PHP, and
                                                    JavaScript development. Check them out, see for yourself, and massively
                                                    level up your development skills in the process.</p>
                                            </div>
                                            <div className="content">
                                                <i className="fa fa-arrow-right text-defult"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/">
                            <div className="col-6">
                                <div className="card">
                                    <div className="card-icon">
                                        <i className="fa fa-newspaper"></i>
                                    </div>
                                    <div className="card-text">
                                        <div className="justify-space">
                                            <div className="content-title">
                                                <h4>Noaphp News</h4>
                                                <p>Noaphp News is a community driven portal and newsletter aggregating all
                                                    of the latest and most important news in the Noaphp ecosystem, including
                                                    new package releases and tutorials.</p>
                                            </div>
                                            <div className="content">
                                                <i className="fa fa-arrow-right text-defult"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>

                        <div className="col-6">
                            <div className="card">
                                <div className="card-icon">
                                    <i className="fa fa-globe-africa"></i>
                                </div>
                                <div className="card-text">
                                    <h4>Vibrant Ecosystem</h4>
                                    <p>Noephp's robust library of first-party tools and libraries, such as Forge, Vapor,
                                        Nova, and Envoyer help you take your projects to the next level. Pair them with
                                        powerful open source libraries like Cashier, Dusk, Echo, Horizon, Sanctum,
                                        Telescope, and more.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Navigation>
    )
}