import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Index from './pages/Index';
import Gallery from './pages/Gallery';

function App() {
  return (
    <Routes>
      <Route path='/' element={<Index />} />
      <Route path='/gallery' element={<Gallery />} />
    </Routes>
  );
}

export default App;
